import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faCheck, faPlus, faBullseye, faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import { Timestamp } from 'firebase/firestore';
import { track } from '@vercel/analytics';
import ReactGA from 'react-ga4';

const STATUS_MESSAGE_DURATION = 1000; // 3 seconds

function WeightLog({ weightEntries, onWeightUpdate, onAddWeight, goalWeight }) {
  const [editingEntry, setEditingEntry] = useState(null);
  const [editedWeight, setEditedWeight] = useState('');
  const [addingWeight, setAddingWeight] = useState(false);
  const [newWeight, setNewWeight] = useState('');
  const [localWeightEntries, setLocalWeightEntries] = useState(weightEntries);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [statusMessages, setStatusMessages] = useState({});

  useEffect(() => {
    setLocalWeightEntries(weightEntries);
  }, [weightEntries]);

  const isValidWeight = (weight) => {
    const weightValue = parseFloat(weight);
    return !isNaN(weightValue) && weightValue >= 1 && weightValue <= 700;
  };

  const handleEditWeight = (entry) => {
    setEditingEntry(entry);
    setEditedWeight(entry.weight.toString());
  };

  const handleWeightChange = (e, setter) => {
    const value = e.target.value;
    if (value === '' || (isValidWeight(value))) {
      setter(value);
    }
  };

  const handleSaveWeight = async (entry) => {
    const updatedWeight = parseFloat(editedWeight);
    if (isValidWeight(updatedWeight)) {
      try {
        const now = new Date();
        await onWeightUpdate(entry.id, updatedWeight, now);
        setLocalWeightEntries(prevEntries =>
          prevEntries.map(e => e.id === entry.id 
            ? { ...e, weight: updatedWeight, date: Timestamp.fromDate(now) }
            : e
          )
        );
        setStatusMessages(prev => ({ ...prev, [entry.id]: { type: 'success', text: 'Updated successfully' } }));
        track('Weight_Updated', { entryId: entry.id });
        ReactGA.event({
          category: 'WeightLog',
          action: 'Update',
          label: 'Weight Entry Updated'
        });
      } catch (error) {
        setStatusMessages(prev => ({ ...prev, [entry.id]: { type: 'error', text: 'Update failed' } }));
        track('Weight_Update_Failed', { error: error.message });
        ReactGA.event({
          category: 'WeightLog',
          action: 'Update',
          label: 'Weight Entry Update Failed',
          value: error.message
        });
      }
      setEditingEntry(null);
    } else {
      setEditingEntry(null);
      setStatusMessages(prev => ({ ...prev, [entry.id]: { type: 'error', text: 'Invalid weight (1-700 kg)' } }));
    }

    // Set timeout to clear the status message
    setTimeout(() => {
      setStatusMessages(prev => {
        const newMessages = { ...prev };
        delete newMessages[entry.id];
        return newMessages;
      });
    }, STATUS_MESSAGE_DURATION);
  };

  const handleAddWeight = async () => {
    const weightToAdd = parseFloat(newWeight);
    if (isValidWeight(weightToAdd)) {
      try {
        const newEntry = await onAddWeight(weightToAdd);
        if (newEntry) {
          setLocalWeightEntries(prevEntries => [newEntry, ...prevEntries]);
          setStatusMessages(prev => ({ ...prev, [newEntry.id]: { type: 'success', text: 'Added successfully' } }));
          track('Weight_Added');
          ReactGA.event({
            category: 'WeightLog',
            action: 'Add',
            label: 'New Weight Entry Added'
          });
          
          // Set timeout to clear the status message
          setTimeout(() => {
            setStatusMessages(prev => {
              const newMessages = { ...prev };
              delete newMessages[newEntry.id];
              return newMessages;
            });
          }, STATUS_MESSAGE_DURATION);
        }
      } catch (error) {
        setStatusMessages(prev => ({ ...prev, 'new': { type: 'error', text: 'Failed to add weight' } }));
        track('Weight_Add_Failed', { error: error.message });
        ReactGA.event({
          category: 'WeightLog',
          action: 'Add',
          label: 'New Weight Entry Add Failed',
          value: error.message
        });
        
        // Clear error message after duration
        setTimeout(() => {
          setStatusMessages(prev => {
            const newMessages = { ...prev };
            delete newMessages['new'];
            return newMessages;
          });
        }, STATUS_MESSAGE_DURATION);
      }
      setAddingWeight(false);
      setNewWeight('');
    } else {
      setStatusMessages(prev => ({ ...prev, 'new': { type: 'error', text: 'Invalid weight (1-700 kg)' } }));
      
      // Clear error message after duration
      setTimeout(() => {
        setStatusMessages(prev => {
          const newMessages = { ...prev };
          delete newMessages['new'];
          return newMessages;
        });
      }, STATUS_MESSAGE_DURATION);
    }
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const generateDateArray = () => {
    const dateArray = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Always add today's date first
    const todayEntry = localWeightEntries.find(e => isToday(e.date.toDate()));
    dateArray.push({ date: today, entry: todayEntry });

    // Add the rest of the dates
    localWeightEntries.forEach(entry => {
      if (!isToday(entry.date.toDate())) {
        dateArray.push({ date: entry.date.toDate(), entry });
      }
    });

    return dateArray;
  };

  const dateArray = generateDateArray();

  const indexOfLastEntry = currentPage * rowsPerPage;
  const indexOfFirstEntry = indexOfLastEntry - rowsPerPage;
  const currentEntries = dateArray.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(dateArray.length / rowsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const latestWeight = weightEntries[0]?.weight || 0;
  const weightDifference = goalWeight ? (goalWeight - latestWeight).toFixed(1) : null;

  return (
    <div>
      {goalWeight && (
        <div className="goal-weight-cards">
          <div className="goal-weight-card">
            <FontAwesomeIcon icon={faBullseye} className="goal-icon" />
            <div className="goal-info">
              <h3>Goal Weight</h3>
              <p>{goalWeight} kg</p>
            </div>
          </div>
          <div className="goal-weight-card">
            <FontAwesomeIcon icon={faBalanceScale} className="goal-icon" />
            <div className="goal-info">
              <h3>Weight to {weightDifference > 0 ? 'Gain' : 'Lose'}</h3>
              <p>{Math.abs(weightDifference)} kg</p>
            </div>
          </div>
        </div>
      )}
      
      <div className="weight-cards">
        {currentEntries.map(({ date, entry }) => {
          const isCurrentDay = isToday(date);

          return (
            <div key={date.toISOString()} className="weight-card">
              <div className="weight-card-content">
                <div className="weight-date-time">
                  <div className="weight-date">{formatDate(date)}</div>
                  {entry && <div className="weight-time">{formatTime(entry.date.toDate())}</div>}
                </div>
                <div className="weight-value-container">
                  {entry ? (
                    <>
                      {isCurrentDay && editingEntry && editingEntry.id === entry.id ? (
                        <>
                          <input
                            type="number"
                            value={editedWeight}
                            onChange={(e) => handleWeightChange(e, setEditedWeight)}
                            className="weight-input"
                            autoFocus
                            step="0.1"
                            min="0"
                          />
                          <button className="save-icon" onClick={() => handleSaveWeight(entry)}>
                            <FontAwesomeIcon icon={faCheck} />
                          </button>
                        </>
                      ) : (
                        <>
                          <span className="weight-value">
                            {entry.weight}
                            <span className="weight-unit">kg</span>
                          </span>
                          {isCurrentDay && (
                            <button className="edit-icon" onClick={() => handleEditWeight(entry)}>
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {isCurrentDay && (
                        addingWeight ? (
                          <>
                            <input
                              type="number"
                              value={newWeight}
                              onChange={(e) => handleWeightChange(e, setNewWeight)}
                              className="weight-input"
                              autoFocus
                              step="0.1"
                              min="0"
                            />
                            <button className="save-icon" onClick={handleAddWeight}>
                              <FontAwesomeIcon icon={faCheck} />
                            </button>
                          </>
                        ) : (
                          <button className="add-weight-icon" onClick={() => setAddingWeight(true)}>
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
              {statusMessages[entry?.id] && (
                <div className={`status-message ${statusMessages[entry.id].type}`}>
                  {statusMessages[entry.id].text}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {statusMessages['new'] && (
        <div className={`status-message ${statusMessages['new'].type}`}>
          {statusMessages['new'].text}
        </div>
      )}
      {totalPages > 1 && (
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
            <button
              key={number}
              onClick={() => paginate(number)}
              className={currentPage === number ? 'active' : ''}
            >
              {number}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default WeightLog;