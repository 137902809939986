import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true // Only for development
});

export async function generateMealWithGPT(mealInfo, userPreferences, existingMeals = [], previousMeals = []) {
  const prompt = `Generate a meal plan for ${mealInfo.name} based on the following requirements:

**Meal Nutritional Goals:**
- Total Calories: ${mealInfo.macros.calories} kcal
- Protein: ${mealInfo.macros.protein}g
- Carbs: ${mealInfo.macros.carbs}g (including fiber)
- Fat: ${mealInfo.macros.fat}g
- Fiber: ${mealInfo.macros.fiber}g (should be about 5% of carb calories, or ${mealInfo.macros.fiber}g)

**User Preferences:**
${formatUserPreferences(userPreferences)}

**Existing Meals for Today (DO NOT REPEAT THESE):**
${existingMeals.join(', ')}

**Previous Meals from Last 3 Days (TRY TO AVOID REPEATING THESE):**
${previousMeals.join(', ')}

**Important Guidelines:**
1. DO NOT include any allergenic foods listed in the "Food Allergies" section.
2. Use precise measurements for ingredients (e.g., grams, milliliters, kilograms, liters). NEVER use vague measurements like "handful" or "some."
3. Adhere to the macro requirements as closely as possible, with a maximum 1% deviation for each macro.
4. The sum of calories and macros from individual ingredients MUST EXACTLY match the total for the meal. Double-check your calculations to ensure this.
5. Ensure that fiber contributes about 5% of the calories from carbohydrates.
6. Provide a detailed recipe with specific cooking instructions, including cooking times and temperatures where applicable.
7. Include fiber content for each ingredient.
8. Include more fruits in earlier meals of the day and more green vegetables/salads in later meals.
9. If the user has more time for cooking (30+ minutes), recommend unique meals. If less time is available, suggest similar meals to previous ones, noting that this saves time.
10. STRICTLY follow the user's dietary preferences and try to use ALL options provided for protein, carbs, and fats across meals.
11. Pay close attention to the user's cuisine preferences and incorporate them into the meal plan.
12. Clearly specify whether each ingredient mentioned is raw or cooked.
13. Include at least 1 portion of vegetables or salad in lunch and dinner, and 1 portion of fruit in breakfast or snacks. Ensure a total of at least 2 portions of vegetables and 2 portions of fruits across all meals.
14. DO NOT suggest any meals that are listed in the "Existing Meals" section.
15. Provide detailed information on spices and herbs used in the recipe, including quantities and any toasting or preparation methods for the spices.
16. Suggest 3 alternate meal ideas with similar calorie profiles. Provide only the names of these alternates.
17. Evaluate user preferences closely for choice of protein and carbs, and evaluate the meals that have already been suggested. Provide more variety accordingly while sticking to the macro and calorie targets.
18. For breakfast, include the user's tea/coffee preferences as specified in their preferences.

Return the meal plan in the following structured JSON format:
{
  "mealName": "String",
  "dishName": "String",
  "description": "String",
  "macros": {
    "protein": Number,
    "carbs": Number,
    "fat": Number,
    "fiber": Number,
    "calories": Number
  },
  "recipe": {
    "ingredients": [
      {
        "name": "String",
        "quantity": "String (with specific units)",
        "state": "String (raw or cooked)",
        "macros": {
          "protein": Number,
          "carbs": Number,
          "fat": Number,
          "fiber": Number,
          "calories": Number
        }
      }
    ],
    "spices": [
      {
        "name": "String",
        "quantity": "String (with specific units)",
        "preparation": "String (e.g., toasted, ground, whole)"
      }
    ],
    "instructions": ["String"],
    "cookingTime": "String"
  },
  "mealTiming": "String",
  "alternates": ["String", "String", "String"]
}

IMPORTANT: Ensure that the sum of macros and calories from all ingredients EXACTLY matches the total macros and calories for the meal. Verify this before returning the response. The total macros in the 'macros' field must be the sum of the macros from all ingredients.

Return only the JSON object without any additional text or formatting.`;

  try {
    const response = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      messages: [
        { role: "system", content: "You are a helpful assistant that generates precise and safe meal plans." },
        { role: "user", content: prompt }
      ],
      temperature: 0.7,
      max_tokens: 2000,
    });

    return response.choices[0].message.content.trim();
  } catch (error) {
    console.error("Error generating meal plan:", error);
    throw error;
  }
}

function formatUserPreferences(preferences) {
  return `
- Dietary Preference: ${preferences.dietaryPreference}
- Meals Per Day: ${preferences.mealsPerDay}
- Protein Sources: ${preferences.proteinSources.join(', ')}
- Carb Sources: ${preferences.carbSources.join(', ')}
- Fat Sources: ${preferences.fatSources.join(', ')}
- Food Allergies: ${preferences.foodAllergies.length > 0 ? preferences.foodAllergies.join(', ') : 'None'}
- Cooking Methods: ${preferences.cookingMethods.join(', ')}
- Spice Tolerance: ${preferences.spiceTolerance}
- Cuisine Preferences: ${preferences.cuisinePreferences.join(', ')}
- Meal Prep Time: ${preferences.mealPrepTime}
- Tea/Coffee Preferences: ${preferences.teaCoffeePreferences.join(', ')}
  `;
}

export async function generateSingleMeal(mealInfo, userPreferences, existingMeals = [], previousMeals = []) {
  return generateMealWithGPT(mealInfo, userPreferences, existingMeals, previousMeals);
}