import { generateMealWithGPT } from './openai';
import { saveMealPlan } from './mealPlanStorage';

const MAX_ATTEMPTS_PER_MEAL = 2;
const DEVIATION_THRESHOLD = 0.10; // 10% deviation threshold

const MEAL_DISTRIBUTIONS = {
    "3 meals (breakfast, lunch, dinner) with no snacks": [
      { name: "Breakfast", percentage: 0.30 },
      { name: "Lunch", percentage: 0.35 },
      { name: "Dinner", percentage: 0.35 }
    ],
    "3 meals with 1-2 snacks": [
      { name: "Breakfast", percentage: 0.25 },
      { name: "Lunch", percentage: 0.30 },
      { name: "Dinner", percentage: 0.30 },
      { name: "Snacks", percentage: 0.15 }
    ],
    "2 large meals (intermittent fasting) with no snacks": [
      { name: "Breakfast", percentage: 0.50 },
      { name: "Dinner", percentage: 0.50 }
    ],
    "2 large meals with 1-2 snacks": [
      { name: "Breakfast", percentage: 0.45 },
      { name: "Dinner", percentage: 0.45 },
      { name: "Snacks", percentage: 0.10 }
    ],
    "5-6 small meals throughout the day": [
      { name: "Pre-Breakfast", percentage: 0.20 },
      { name: "Breakfast", percentage: 0.20 },
      { name: "Lunch", percentage: 0.20 },
      { name: "Evening Snack", percentage: 0.20 },
      { name: "Dinner", percentage: 0.20 }
    ]
  };

function distributeMacros(totalMacros, mealsPerDay) {
  //console.log("Distributing macros for meals per day:", mealsPerDay);
  const distribution = MEAL_DISTRIBUTIONS[mealsPerDay];
  if (!distribution) {
    throw new Error(`Invalid meals per day option: ${mealsPerDay}`);
  }

  const distributedMeals = distribution.map(meal => {
    const carbCalories = totalMacros.carbs * 4 * meal.percentage;
    const fiberCalories = carbCalories * 0.05; // 5% of carb calories
    const fiberGrams = fiberCalories / 2; // Fiber has 2 calories per gram

    return {
      name: meal.name,
      macros: {
        protein: Math.round(totalMacros.protein * meal.percentage),
        carbs: Math.round(totalMacros.carbs * meal.percentage),
        fat: Math.round(totalMacros.fat * meal.percentage),
        fiber: Math.round(fiberGrams),
        calories: Math.round(totalMacros.calories * meal.percentage)
      }
    };
  });
  //console.log("Distributed meals:", distributedMeals);
  return distributedMeals;
}

function calculateMacroDeviation(actual, target) {
  const deviation = Object.keys(target).reduce((deviation, macro) => {
    deviation[macro] = (actual[macro] - target[macro]) / target[macro];
    return deviation;
  }, {});
  //console.log("Calculated macro deviation:", deviation);
  return deviation;
}

function isDeviationAcceptable(deviation) {
  const isAcceptable = Object.values(deviation).every(value => Math.abs(value) <= DEVIATION_THRESHOLD);
  //console.log("Is deviation acceptable:", isAcceptable);
  return isAcceptable;
}

function adjustRemainingMacros(remainingMeals, currentDeviation) {
  //console.log("Adjusting remaining meals. Current deviation:", currentDeviation);
  const totalRemainingMeals = remainingMeals.length;
  return remainingMeals.map((meal, index) => {
    const isLastMeal = index === totalRemainingMeals - 1;
    const adjustedMacros = {};
    
    Object.keys(meal.macros).forEach(macro => {
      if (isLastMeal) {
        adjustedMacros[macro] = Math.max(0, meal.macros[macro] - currentDeviation[macro]);
      } else {
        const adjustment = currentDeviation[macro] / totalRemainingMeals;
        adjustedMacros[macro] = Math.max(0, meal.macros[macro] - adjustment);
      }
    });

    //console.log(`Adjusted macros for ${meal.name}:`, adjustedMacros);
    return { ...meal, macros: adjustedMacros };
  });
}

async function generateMeal(mealInfo, userPreferences, acceptedMealNames, attempt = 1) {
  if (attempt >= MAX_ATTEMPTS_PER_MEAL) {
    //console.log(`Max attempts (${MAX_ATTEMPTS_PER_MEAL}) reached, returning last generated meal plan`);
    return mealInfo; // or some default meal plan
  }

  //console.log(`Generating meal for ${mealInfo.name}, attempt ${attempt}/${MAX_ATTEMPTS_PER_MEAL}`);
  //console.log("Meal info:", mealInfo);
  //console.log("User preferences:", userPreferences);
  
  const response = await generateMealWithGPT(mealInfo, userPreferences);
  //console.log("GPT Response:", response);
  
  const mealPlan = parseMealPlanResponse(response);
  //console.log("Parsed meal plan:", mealPlan);
  
  const deviation = calculateMacroDeviation(mealPlan.macros, mealInfo.macros);

  if (!isDeviationAcceptable(deviation)) {
    //console.log(`Deviation not acceptable, attempting again (${attempt + 1}/${MAX_ATTEMPTS_PER_MEAL})`);
    return generateMeal(mealInfo, userPreferences, [...acceptedMealNames, mealPlan.dishName], attempt + 1);
  }

  return mealPlan;
}

export async function generateFullMealPlan(totalMacros, userPreferences, userId) {
  //console.log("Generating full meal plan");
  //console.log("Total macros:", totalMacros);
  //console.log("User preferences:", userPreferences);
  
  let mealsPerDay = userPreferences.mealsPerDay;
  // Map user preference to MEAL_DISTRIBUTIONS key
  const mealDistributionKey = {
    "3 meals (breakfast, lunch, dinner) with no snacks": "3 meals (breakfast, lunch, dinner) with no snacks",
    "3 meals with 1-2 snacks": "3 meals with 1-2 snacks",
    "2 large meals (intermittent fasting) with no snacks": "2 large meals (intermittent fasting) with no snacks",
    "2 large meals with 1-2 snacks": "2 large meals with 1-2 snacks",
    "5-6 small meals throughout the day": "5-6 small meals throughout the day"
  }[mealsPerDay] || "3 meals with 1-2 snacks"; // Default to 3 meals with 1-2 snacks if no match

  let distributedMeals = distributeMacros(totalMacros, mealDistributionKey);
  const fullMealPlan = [];
  let currentDeviation = { protein: 0, carbs: 0, fat: 0, fiber: 0, calories: 0 };
  let totalPlanMacros = { protein: 0, carbs: 0, fat: 0, fiber: 0, calories: 0 };
  let acceptedMealNames = [];

  for (let i = 0; i < distributedMeals.length; ) {
    //console.log(`Generating meal ${i + 1} of ${distributedMeals.length}`);
    const mealInfo = distributedMeals[i];
    const meal = await generateMeal(mealInfo, userPreferences, acceptedMealNames);
    fullMealPlan.push(meal);

    //console.log(`Meal ${i + 1} (${meal.mealName}) macros:`, meal.macros);
    
    // Update total plan macros
    Object.keys(totalPlanMacros).forEach(macro => {
      totalPlanMacros[macro] += meal.macros[macro];
    });

    const mealDeviation = calculateMacroDeviation(meal.macros, mealInfo.macros);
    //console.log(`Meal ${i + 1} deviation:`, mealDeviation);
    
    // Update current deviation
    currentDeviation = Object.keys(currentDeviation).reduce((acc, macro) => {
      acc[macro] += (meal.macros[macro] - mealInfo.macros[macro]);
      return acc;
    }, currentDeviation);
    //console.log("Current cumulative deviation:", currentDeviation);

    // Add the meal name to acceptedMealNames if it passes the deviation check
    if (isDeviationAcceptable(mealDeviation)) {
      acceptedMealNames.push(meal.dishName);
    }

    if (i < distributedMeals.length - 1) {
      distributedMeals = adjustRemainingMacros(distributedMeals.slice(i + 1), currentDeviation);
      //console.log("Adjusted remaining meals:", distributedMeals);
      // Reset i to 0 as we're now working with a new array of remaining meals
      i = 0;
    } else {
      // If we're on the last meal, increment i to end the loop
      i++;
    }
  }

  //console.log("Full meal plan generated:", fullMealPlan);
  //console.log("Total plan macros:", totalPlanMacros);

  // Final deviation check
  const finalDeviation = calculateMacroDeviation(totalPlanMacros, totalMacros);
  //console.log("Final plan deviation from target:", finalDeviation);

  const fullMealPlanResult = {
    meals: fullMealPlan,
    totalMacros: totalPlanMacros,
    finalDeviation
  };

  // Save the generated meal plan
  await saveMealPlan(userId, fullMealPlanResult);

  return fullMealPlanResult;
}

function parseMealPlanResponse(response) {
  try {
    const mealPlan = JSON.parse(response);
    
    // Validate the structure of the parsed object
    if (!mealPlan.mealName || !mealPlan.dishName || !mealPlan.description || 
        !mealPlan.macros || !mealPlan.recipe || !mealPlan.mealTiming || !mealPlan.alternates) {
      throw new Error("Invalid meal plan structure");
    }

    // Ensure all macros are numbers and not NaN
    mealPlan.macros = Object.entries(mealPlan.macros).reduce((acc, [key, value]) => {
      acc[key] = Number.isNaN(Number(value)) ? 0 : Number(value);
      return acc;
    }, {});

    // Ensure all ingredient macros are numbers and not NaN
    mealPlan.recipe.ingredients = mealPlan.recipe.ingredients.map(ingredient => ({
      ...ingredient,
      macros: Object.entries(ingredient.macros).reduce((acc, [key, value]) => {
        acc[key] = Number.isNaN(Number(value)) ? 0 : Number(value);
        return acc;
      }, {})
    }));

    // Ensure alternates is an array of strings
    if (!Array.isArray(mealPlan.alternates) || mealPlan.alternates.some(alt => typeof alt !== 'string')) {
      throw new Error("Invalid alternates structure");
    }

    //console.log("Parsed meal plan:", mealPlan);
    return mealPlan;
  } catch (error) {
    console.error("Error parsing meal plan response:", error);
    throw new Error("Failed to parse meal plan response");
  }
}export async function generateSingleMeal(mealInfo, userPreferences, existingMeals) {
  //console.log(`Generating single meal for ${mealInfo.name}`);
  //console.log("Meal info:", mealInfo);
  //console.log("User preferences:", userPreferences);
  //console.log("Existing meals:", existingMeals);

  const response = await generateMealWithGPT(mealInfo, userPreferences, existingMeals);
  //console.log("GPT Response:", response);
  
  const mealPlan = parseMealPlanResponse(response);
  //console.log("Parsed meal plan:", mealPlan);

  return mealPlan;
}

