import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faPlay, faArrowLeft, faArrowRight, faUtensils, faSpinner, faExclamationTriangle, faEdit, faHistory, faFire, faEgg, faCarrot, faOilCan, faTimes, faCheck, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { generateFullMealPlan, generateSingleMeal } from './utils/mealPlanGenerator';
import { saveMealPlan, getTodaysMealPlan, mealPlanExistsForToday, saveUserPreferences, getUserPreferences, getPreviousMealPlans } from './utils/mealPlanStorage';
import MealPlanDisplay from './MealPlanDisplay';
import './MealPlanner.css';
import './MealPlannerNew.css';
import { Analytics } from '@vercel/analytics/react';
import { track } from '@vercel/analytics';
import ReactGA from 'react-ga4';
import { format } from 'date-fns';

function MealPlanner({ caloriesAndMacros, mealPlannerData, setMealPlannerData, handleTabChange, user }) {
  const [showQuestions, setShowQuestions] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [showSummary, setShowSummary] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [todaysMealPlan, setTodaysMealPlan] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);
  const [editingPreferences, setEditingPreferences] = useState(false);
  const [error, setError] = useState('');
  const [isGeneratingSingleMeal, setIsGeneratingSingleMeal] = useState(false);
  const [showPreviousMealPlans, setShowPreviousMealPlans] = useState(false);
  const [previousMealPlans, setPreviousMealPlans] = useState([]);
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [showDietaryPreferences, setShowDietaryPreferences] = useState(false);
  const [showPreferencesModal, setShowPreferencesModal] = useState(false);
  const [regeneratingMealIndex, setRegeneratingMealIndex] = useState(null);
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [hasPreviousMealPlans, setHasPreviousMealPlans] = useState(false);
  const [expandedPlanDate, setExpandedPlanDate] = useState(null);

  useEffect(() => {
    if (user && user.uid) {
      checkTodaysMealPlan();
      fetchUserPreferences();
      checkPreviousMealPlans();
      track('MealPlanner_Viewed');
      ReactGA.event({
        category: 'MealPlanner',
        action: 'View',
        label: 'MealPlanner Viewed'
      });
    }
  }, [user]);

  useEffect(() => {
    if (mealPlannerData) {
      setAnswers(mealPlannerData.answers || {});
    }
  }, [mealPlannerData]);

  useEffect(() => {
    if (userPreferences) {
      setAnswers(formatAnswersFromPreferences(userPreferences));
    }
  }, [userPreferences]);

  const formatAnswersFromPreferences = (prefs) => {
    return {
      0: [prefs.dietaryPreference],
      1: [prefs.mealsPerDay],
      2: prefs.proteinSources,
      3: prefs.carbSources,
      4: prefs.fatSources,
      5: prefs.foodAllergies,
      6: prefs.cookingMethods,
      7: [prefs.spiceTolerance],
      8: prefs.cuisinePreferences,
      9: [prefs.mealPrepTime],
      10: prefs.teaCoffeePreferences
    };
  };

  const checkTodaysMealPlan = async () => {
    const exists = await mealPlanExistsForToday(user.uid);
    if (exists) {
      const mealPlan = await getTodaysMealPlan(user.uid);
      setTodaysMealPlan(mealPlan);
    } else {
      setTodaysMealPlan(null);
    }
  };

  const fetchUserPreferences = async () => {
    const prefs = await getUserPreferences(user.uid);
    //console.log("Fetched user preferences:", prefs);
    setUserPreferences(prefs);
    if (prefs) {
      setAnswers(prefs);
    }
  };

  const checkPreviousMealPlans = async () => {
    try {
      const plans = await getPreviousMealPlans(user.uid, 7);
      setHasPreviousMealPlans(plans.length > 0);
    } catch (error) {
      console.error('Error checking previous meal plans:', error);
    }
  };

  const handleStartMealPlanning = () => {
    if (!userPreferences) {
      setShowQuestions(true);
    } else {
      handleGenerateMealPlan();
    }
  };

  const handleEditPreferences = () => {
    setShowQuestionnaire(true);
    setCurrentQuestion(0);
  };

  const handleCloseQuestionnaire = () => {
    setShowQuestionnaire(false);
  };

  const questions = [
    {
      question: "What is your primary dietary preference?",
      type: "radio",
      options: [
        "Vegetarian (includes dairy, no meat, fish, or eggs)",
        "Non-Vegetarian (includes all meat, fish, and eggs)",
        "Eggetarian (vegetarian with eggs)",
        "Vegan (no animal products, no dairy or eggs)",
        "Pescatarian (vegetarian with fish)"
      ]
    },
    {
      question: "How many meals and snacks do you prefer to have each day?",
      type: "radio",
      options: [
        "3 meals (breakfast, lunch, dinner) with no snacks",
        "3 meals with 1-2 snacks",
        "2 large meals (intermittent fasting) with no snacks",
        "2 large meals with 1-2 snacks",
        "5-6 small meals throughout the day"
      ]
    },
    {
      question: "What are your top 3 preferred sources of protein?",
      type: "checkbox",
      minSelections: 3,
      maxSelections: 3,
      options: [
        "Chicken", "Fish", "Mutton/Lamb", "Eggs", "Lentils (Daal)",
        "Beans (Chickpeas, Rajma)", "Paneer (Indian cottage cheese)",
        "Tofu", "Whey protein"
      ]
    },
    {
      question: "What are your top 3 preferred sources of carbohydrates?",
      type: "checkbox",
      minSelections: 3,
      maxSelections: 3,
      options: [
        "White rice", "Brown rice", "Whole wheat roti/chapati", "Quinoa",
        "Oats", "Sweet potatoes", "Whole grain bread", "Millet", "Barley"
      ]
    },
    {
      question: "What are your top 3 preferred sources of fats?",
      type: "checkbox",
      minSelections: 3,
      maxSelections: 3,
      options: [
        "Ghee", "Coconut oil", "Olive oil", "Mustard oil",
        "Nuts (almonds, walnuts, cashews)", "Seeds (pumpkin, sunflower, flax)",
        "Avocado", "Fatty fish (salmon, mackerel)", "Full-fat dairy products"
      ]
    },
    {
      question: "Do you have any of these food allergies or intolerances?",
      type: "checkbox",
      options: [
        "None", "Dairy (milk, cheese, butter)", "Gluten (wheat, barley, rye)",
        "Eggs", "Peanuts", "Tree nuts (almonds, cashews, walnuts)", "Soy",
        "Fish", "Shellfish"
      ]
    },
    {
      question: "What are your 2 preferred cooking methods?",
      type: "checkbox",
      minSelections: 2,
      maxSelections: 2,
      options: [
        "Stovetop cooking", "Oven baking", "Grilling", "Pressure cooking",
        "Air frying", "Slow cooking", "Steaming"
      ]
    },
    {
      question: "How would you describe your spice tolerance?",
      type: "radio",
      options: [
        "Mild - prefer little to no spice",
        "Medium - enjoy some heat but nothing too intense",
        "Hot - love spicy food",
        "Extra hot - the spicier, the better"
      ]
    },
    {
      question: "Which 2 Indian regional cuisines do you prefer?",
      type: "checkbox",
      minSelections: 2,
      maxSelections: 2,
      options: [
        "North Indian (e.g., Punjabi, Kashmiri)",
        "South Indian (e.g., Tamil, Kerala)",
        "East Indian (e.g., Bengali, Odiya)",
        "West Indian (e.g., Gujarati, Maharashtrian)",
        "Central Indian (e.g., Rajasthani, Madhya Pradesh)",
        "North-East Indian (e.g., Assamese, Naga)"
      ]
    },
    {
      question: "How much time can you typically dedicate to meal preparation each day?",
      type: "radio",
      options: [
        "Less than 15 minutes",
        "15-30 minutes",
        "30-60 minutes",
        "More than 60 minutes",
        "Prefer batch cooking 1-2 times per week"
      ]
    },
    {
      question: "What are your preferences for tea and coffee?",
      type: "checkbox",
      options: [
        "Black coffee without sugar",
        "Black coffee with sugar",
        "Coffee with milk without sugar",
        "Coffee with milk and sugar",
        "Black tea without sugar",
        "Black tea with sugar",
        "Tea with milk without sugar",
        "Tea with milk and sugar",
        "I don't drink tea or coffee"
      ]
    }
  ];
  
  const handleAnswer = (option) => {
    const currentQuestionData = questions[currentQuestion];
    let updatedAnswers = { ...answers };

    if (currentQuestionData.type === "radio") {
      updatedAnswers[currentQuestion] = [option];
    } else if (currentQuestionData.type === "checkbox") {
      if (!updatedAnswers[currentQuestion]) {
        updatedAnswers[currentQuestion] = [];
      }
      
      if (updatedAnswers[currentQuestion].includes(option)) {
        updatedAnswers[currentQuestion] = updatedAnswers[currentQuestion].filter(item => item !== option);
      } else if (updatedAnswers[currentQuestion].length < currentQuestionData.maxSelections) {
        updatedAnswers[currentQuestion] = [...updatedAnswers[currentQuestion], option];
      }
    }

    setAnswers(updatedAnswers);
    setError('');
  };

  const handleNext = () => {
    const currentQuestionData = questions[currentQuestion];
    if (!answers[currentQuestion] || 
        (currentQuestionData.type === "checkbox" && 
         answers[currentQuestion].length < (currentQuestionData.minSelections || 0))) {
      setError(`Please select ${currentQuestionData.minSelections} option${currentQuestionData.minSelections > 1 ? 's' : ''}.`);
      return;
    }

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setError('');
    } else {
      setShowSummary(true);
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setError('');
    }
  };

  const handleGenerateMealPlan = async () => {
    if (!caloriesAndMacros || !caloriesAndMacros.macros) {
      console.error('Calories and macros data is missing or incomplete');
      alert('Unable to generate meal plan. Please calculate your daily calorie requirement first.');
      return;
    }

    try {
      setIsGenerating(true);
      track('MealPlan_Generation_Started');
      ReactGA.event({
        category: 'MealPlanner',
        action: 'Generate',
        label: 'Meal Plan Generation Started'
      });

      const totalMacros = {
        protein: caloriesAndMacros.macros.protein.grams,
        carbs: caloriesAndMacros.macros.carbs.grams,
        fat: caloriesAndMacros.macros.fat.grams,
        calories: caloriesAndMacros.goalCalories
      };
      
      let preferencesToUse;
      if (editingPreferences || !userPreferences) {
        preferencesToUse = formatUserPreferences(answers);
      } else {
        preferencesToUse = userPreferences;
      }
      
      //console.log("Preferences being used for meal generation:", preferencesToUse);

      if (!preferencesToUse || !preferencesToUse.mealsPerDay) {
        throw new Error('Invalid user preferences. Please make sure all questions are answered.');
      }

      const generatedMealPlan = await generateFullMealPlan(totalMacros, preferencesToUse, user.uid);
      
      // Store the original meals
      const mealPlanWithOriginals = {
        ...generatedMealPlan,
        originalMeals: [...generatedMealPlan.meals]
      };

      await saveMealPlan(user.uid, mealPlanWithOriginals);
      setTodaysMealPlan(mealPlanWithOriginals);
      
      if (editingPreferences || !userPreferences) {
        const formattedPreferences = formatUserPreferences(answers);
        try {
          await saveUserPreferences(user.uid, formattedPreferences);
          setUserPreferences(formattedPreferences);
          setEditingPreferences(false);
        } catch (prefError) {
          console.error("Error saving user preferences:", prefError);
          alert("Your preferences couldn't be saved. You may need to set them again next time.");
        }
      }
      
      setShowQuestions(false);
      setShowSummary(false);

      track('MealPlan_Generation_Completed');
      ReactGA.event({
        category: 'MealPlanner',
        action: 'Generate',
        label: 'Meal Plan Generation Completed'
      });
    } catch (error) {
      console.error('Error generating meal plan:', error);
      alert(`Error: ${error.message}. Unable to generate meal plan.`);
      track('MealPlan_Generation_Failed', { error: error.message });
      ReactGA.event({
        category: 'MealPlanner',
        action: 'Generate',
        label: 'Meal Plan Generation Failed',
        value: error.message
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const formatUserPreferences = (answers) => {
    const formatted = {
      dietaryPreference: answers[0]?.[0] || "None",
      mealsPerDay: answers[1]?.[0] || "3 meals with 1-2 snacks",
      proteinSources: answers[2] || [],
      carbSources: answers[3] || [],
      fatSources: answers[4] || [],
      foodAllergies: answers[5]?.filter(allergy => allergy !== "None") || [],
      cookingMethods: answers[6] || [],
      spiceTolerance: answers[7]?.[0] || "None",
      cuisinePreferences: answers[8] || [],
      mealPrepTime: answers[9]?.[0] || "None",
      teaCoffeePreferences: answers[10] || []
    };
    console.log("Formatted user preferences:", formatted);
    return formatted;
  };

  const formatPreferencesFromAnswers = (answers) => {
    return {
      dietaryPreference: answers[0]?.[0] || '',
      mealsPerDay: answers[1]?.[0] || '',
      proteinSources: answers[2] || [],
      carbSources: answers[3] || [],
      fatSources: answers[4] || [],
      foodAllergies: answers[5] || [],
      cookingMethods: answers[6] || [],
      spiceTolerance: answers[7]?.[0] || '',
      cuisinePreferences: answers[8] || [],
      mealPrepTime: answers[9]?.[0] || '',
      teaCoffeePreferences: answers[10] || []
    };
  };

  const handleFinishQuestionnaire = async () => {
    try {
      const formattedPreferences = formatPreferencesFromAnswers(answers);
      await saveUserPreferences(user.uid, formattedPreferences);
      setUserPreferences(formattedPreferences);
      setShowQuestionnaire(false);
      setShowSavedMessage(true);
      setTimeout(() => setShowSavedMessage(false), 3000);
    } catch (error) {
      console.error('Error saving preferences:', error);
      alert('Failed to save preferences. Please try again.');
    }
  };

  const handleRecommendNewMeal = async (index) => {
    setRegeneratingMealIndex(index);
    try {
      if (!todaysMealPlan || !todaysMealPlan.meals) {
        console.error('Invalid meal plan');
        alert('Unable to recommend a new meal. Meal plan is not available.');
        return;
      }

      const currentMeal = todaysMealPlan.meals[index];

      if (!currentMeal) {
        console.error('Current meal not found');
        alert('Unable to recommend a new meal. Current meal information is not available.');
        return;
      }

      const mealInfo = {
        name: currentMeal.mealName,
        macros: currentMeal.macros
      };

      // Get the names of all existing meals for today
      const existingMeals = todaysMealPlan.meals.map(meal => meal.dishName);

      // Fetch meal plans for the last 3 days
      const last3DaysPlans = await getPreviousMealPlans(user.uid, 3);
      const previousMeals = last3DaysPlans.flatMap(plan => 
        plan.mealPlan.meals.map(meal => meal.dishName)
      );

      const newMeal = await generateSingleMeal(mealInfo, userPreferences, existingMeals, previousMeals);

      if (newMeal) {
        const updatedMealPlan = {
          ...todaysMealPlan,
          meals: todaysMealPlan.meals.map((meal, i) => 
            i === index ? newMeal : meal
          )
        };

        await saveMealPlan(user.uid, updatedMealPlan);
        setTodaysMealPlan(updatedMealPlan);
      } else {
        throw new Error('Failed to generate a new meal');
      }

      track('New_Meal_Recommended', { mealIndex: index });
      ReactGA.event({
        category: 'MealPlanner',
        action: 'Recommend',
        label: 'New Meal Recommended',
        value: index
      });
    } catch (error) {
      console.error('Error recommending new meal:', error);
      alert(`Error: ${error.message}. Unable to recommend a new meal.`);
      track('New_Meal_Recommendation_Failed', { error: error.message });
      ReactGA.event({
        category: 'MealPlanner',
        action: 'Recommend',
        label: 'New Meal Recommendation Failed',
        value: error.message
      });
    } finally {
      setRegeneratingMealIndex(null);
    }
  };

  const handleViewPreviousMealPlans = async () => {
    setShowPreviousMealPlans(true);
    track('Previous_MealPlans_Viewed');
    try {
      const plans = await getPreviousMealPlans(user.uid, 7);
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);
      
      const last7Days = Array.from({length: 7}, (_, i) => {
        const date = new Date(yesterday);
        date.setDate(date.getDate() - i);
        return date;
      });

      const formattedPlans = last7Days.map(date => {
        const plan = plans.find(p => {
          const planDate = p.date.toDate();
          return format(planDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
        });
        return {
          date: date,
          plan: plan ? plan.mealPlan : null
        };
      });

      setPreviousMealPlans(formattedPlans);
    } catch (error) {
      console.error('Error fetching previous meal plans:', error);
      alert('Failed to fetch previous meal plans. Please try again.');
      track('Previous_MealPlans_Fetch_Failed', { error: error.message });
    }
  };

  const togglePlanExpansion = (date) => {
    if (expandedPlanDate === date) {
      setExpandedPlanDate(null);
    } else {
      setExpandedPlanDate(date);
    }
  };

  const renderPreferenceCards = () => (
    <div className="preference-cards">
      {questions.map((q, index) => (
        <div key={index} className="preference-card">
          <h3>{q.question}</h3>
          {q.options.map((option, optionIndex) => (
            <label key={optionIndex}>
              <input
                type={q.type}
                checked={answers[index]?.includes(option) || false}
                onChange={() => handleAnswer(option)}
                disabled={
                  q.type === "checkbox" &&
                  q.maxSelections &&
                  answers[index]?.length >= q.maxSelections &&
                  !answers[index]?.includes(option)
                }
              />
              {option}
            </label>
          ))}
        </div>
      ))}
    </div>
  );

  const renderMacroGoals = () => (
    <div className="macro-summary">
      <div className="macro-card">
        <h3>Calories</h3>
        <p>{caloriesAndMacros.goalCalories} kcal</p>
      </div>
      <div className="macro-card">
        <h3>Protein</h3>
        <p>{caloriesAndMacros.macros.protein.grams}g</p>
      </div>
      <div className="macro-card">
        <h3>Carbs</h3>
        <p>{caloriesAndMacros.macros.carbs.grams}g</p>
      </div>
      <div className="macro-card">
        <h3>Fat</h3>
        <p>{caloriesAndMacros.macros.fat.grams}g</p>
      </div>
    </div>
  );

  const renderDietaryPreferences = () => (
    <div className="dietary-preferences">
      <h3>Your Dietary Preferences</h3>
      {userPreferences ? (
        <>
          <ul>
            <li><strong>Dietary:</strong> {userPreferences.dietaryPreference}</li>
            <li><strong>Meals/Day:</strong> {userPreferences.mealsPerDay}</li>
            <li><strong>Protein:</strong> {userPreferences.proteinSources.join(', ')}</li>
            <li><strong>Carbs:</strong> {userPreferences.carbSources.join(', ')}</li>
            <li><strong>Fats:</strong> {userPreferences.fatSources.join(', ')}</li>
            <li><strong>Allergies:</strong> {userPreferences.foodAllergies.join(', ') || 'None'}</li>
            <li><strong>Cooking:</strong> {userPreferences.cookingMethods.join(', ')}</li>
            <li><strong>Spice:</strong> {userPreferences.spiceTolerance}</li>
            <li><strong>Cuisine:</strong> {userPreferences.cuisinePreferences.join(', ')}</li>
            <li><strong>Prep Time:</strong> {userPreferences.mealPrepTime}</li>
            <li><strong>Drinks:</strong> {userPreferences.teaCoffeePreferences.join(', ')}</li>
          </ul>
          <button className="edit-preferences-button" onClick={() => setShowQuestionnaire(!showQuestionnaire)}>
            {showQuestionnaire ? 'Hide Questionnaire' : 'Edit Preferences'}
          </button>
        </>
      ) : (
        <div>
          <p>You haven't set your dietary preferences yet.</p>
          <button className="edit-preferences-button" onClick={() => setShowQuestionnaire(true)}>
            Set Preferences
          </button>
        </div>
      )}
    </div>
  );

  const MealCard = ({ meal, index }) => {
    const [expanded, setExpanded] = useState(false);
    const isRegenerating = regeneratingMealIndex === index;

    return (
      <div className="meal-card">
        <h3>{meal.dishName}</h3>
        <p className="meal-timing">{meal.mealTiming}</p>
        <p>{meal.description}</p>
        <div className="meal-macros">
          <div className="calories-item">
            <FontAwesomeIcon icon={faFire} />
            <span>{meal.macros.calories} kcal</span>
          </div>
          <div className="macro-items">
            <div className="macro-item">
              <FontAwesomeIcon icon={faEgg} />
              <span>{meal.macros.protein}g protein</span>
            </div>
            <div className="macro-item">
              <FontAwesomeIcon icon={faCarrot} />
              <span>{meal.macros.carbs}g carbs</span>
            </div>
            <div className="macro-item">
              <FontAwesomeIcon icon={faOilCan} />
              <span>{meal.macros.fat}g fat</span>
            </div>
          </div>
        </div>
        <button className="expand-button" onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Collapse' : 'Expand'}
        </button>
        <button 
          className={`recommend-button ${isRegenerating ? 'regenerating' : ''}`}
          onClick={() => handleRecommendNewMeal(index)}
          disabled={isRegenerating}
        >
          {isRegenerating ? (
            <>
              <FontAwesomeIcon icon={faSpinner} spin /> Regenerating...
            </>
          ) : (
            <>
              Recommend Another
            </>
          )}
        </button>
        {expanded && (
          <div className="meal-details">
            <div className="meal-details-section">
              <h4>Ingredients:</h4>
              <ul>
                {meal.recipe.ingredients.map((ingredient, idx) => (
                  <li key={idx}>{ingredient.name}: {ingredient.quantity} ({ingredient.state})</li>
                ))}
              </ul>
            </div>
            {meal.recipe.spices && meal.recipe.spices.length > 0 && (
              <div className="meal-details-section">
                <h4>Spices:</h4>
                <ul>
                  {meal.recipe.spices.map((spice, idx) => (
                    <li key={idx}>{spice.name}: {spice.quantity} ({spice.preparation})</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="meal-details-section">
              <h4>Instructions:</h4>
              <ol>
                {meal.recipe.instructions.map((instruction, idx) => (
                  <li key={idx}>{instruction}</li>
                ))}
              </ol>
            </div>
            <div className="meal-details-section">
              <h4>Cooking Time:</h4>
              <p>{meal.recipe.cookingTime}</p>
            </div>
            {meal.alternates && meal.alternates.length > 0 && (
              <div className="meal-details-section">
                <h4>Alternate Options:</h4>
                <ul>
                  {meal.alternates.map((alternate, idx) => (
                    <li key={idx}>{alternate}</li>
                  ))}
                </ul>
              </div>
            )}
            {meal.mealName.toLowerCase().includes('breakfast') && meal.teaCoffeePreference && (
              <div className="meal-details-section">
                <h4>Beverage:</h4>
                <p>{meal.teaCoffeePreference}</p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderGenerateButton = () => (
    <button 
      className={`theme-button ${isGenerating ? 'disabled' : ''}`}
      onClick={isGenerating ? null : handleGenerateMealPlan}
      disabled={isGenerating}
      style={{ pointerEvents: isGenerating ? 'none' : 'auto' }}
    >
      {isGenerating ? (
        <>
          <FontAwesomeIcon icon={faSpinner} spin /> Generating Meal Plan...
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faUtensils} /> Generate Today's Meal Plan
        </>
      )}
    </button>
  );

  const renderQuestionnaire = () => (
    <div className="questionnaire">
      <div className="question-card">
        <h3>Question {currentQuestion + 1} of {questions.length}</h3>
        <p>{questions[currentQuestion].question}</p>
        {questions[currentQuestion].minSelections && (
          <p className="selection-instruction">Please select exactly {questions[currentQuestion].minSelections} option{questions[currentQuestion].minSelections > 1 ? 's' : ''}.</p>
        )}
        <div className="options">
          {questions[currentQuestion].options.map((option, index) => (
            <label key={index} className="option-label">
              <input
                type={questions[currentQuestion].type}
                checked={answers[currentQuestion]?.includes(option) || false}
                onChange={() => handleAnswer(option)}
                disabled={
                  questions[currentQuestion].type === "checkbox" &&
                  !answers[currentQuestion]?.includes(option) &&
                  answers[currentQuestion]?.length >= questions[currentQuestion].maxSelections
                }
              />
              {option}
            </label>
          ))}
        </div>
      </div>
      {error && <p className="error-message">{error}</p>}
      <div className="navigation-buttons">
        <button className="nav-button" onClick={handlePrevious} disabled={currentQuestion === 0}>
          <FontAwesomeIcon icon={faArrowLeft} /> Previous
        </button>
        <button className="nav-button" onClick={currentQuestion < questions.length - 1 ? handleNext : handleFinishQuestionnaire}>
          {currentQuestion < questions.length - 1 ? (
            <>Next <FontAwesomeIcon icon={faArrowRight} /></>
          ) : (
            <>Finish <FontAwesomeIcon icon={faCheck} /></>
          )}
        </button>
      </div>
    </div>
  );

  const renderPreferencesModal = () => (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={() => setShowPreferencesModal(false)}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <h2>Edit Your Preferences</h2>
        {renderPreferenceCards()}
      </div>
    </div>
  );

  if (!caloriesAndMacros || !caloriesAndMacros.macros) {
    return (
      <div className="meal-planner">
        <h2>Meal Planner</h2>
        <p>Please calculate your daily calorie requirement first.</p>
        <button className="theme-button" onClick={() => handleTabChange('goal')}>
          <FontAwesomeIcon icon={faCalculator} /> Calculate Your Daily Calorie Requirement
        </button>
      </div>
    );
  }

  return (
    <div className="meal-planner">
      <h2>Meal Planner</h2>
      {renderMacroGoals()}
      {renderDietaryPreferences()}
      {showQuestionnaire && renderQuestionnaire()}
      {showSavedMessage && (
        <div className="saved-message">
          <FontAwesomeIcon icon={faCheck} /> Your dietary preferences have been saved.
        </div>
      )}
      
      {!todaysMealPlan && (
        <div>
          <p>You haven't generated a meal plan for today yet.</p>
          {userPreferences && renderGenerateButton()}
        </div>
      )}

      {todaysMealPlan && (
        <div className="meal-cards">
          {todaysMealPlan.meals.map((meal, index) => (
            <MealCard key={index} meal={meal} index={index} />
          ))}
        </div>
      )}

      <button className="view-previous-plans-button" onClick={handleViewPreviousMealPlans}>
        <FontAwesomeIcon icon={faHistory} /> View Previous Meal Plans
      </button>

      {showPreviousMealPlans && (
        <div className="previous-meal-plans">
          <h3>Previous Meal Plans</h3>
          {previousMealPlans.map((planData, index) => (
            <div key={index} className="previous-meal-plan">
              <h4 onClick={() => togglePlanExpansion(planData.date)}>
                {format(planData.date, 'MMMM d, yyyy')}
                <FontAwesomeIcon 
                  icon={expandedPlanDate === planData.date ? faChevronUp : faChevronDown} 
                  className="expand-icon"
                />
              </h4>
              {expandedPlanDate === planData.date && (
                <div className="expanded-meal-plan">
                  {planData.plan ? (
                    <div>
                      {planData.plan.meals.map((meal, mealIndex) => (
                        <div key={mealIndex} className="previous-meal">
                          <h5>{meal.mealName}</h5>
                          <p>{meal.dishName}</p>
                          <div className="meal-macros">
                            <span><FontAwesomeIcon icon={faFire} /> {meal.macros.calories} kcal</span>
                            <span><FontAwesomeIcon icon={faEgg} /> {meal.macros.protein}g</span>
                            <span><FontAwesomeIcon icon={faCarrot} /> {meal.macros.carbs}g</span>
                            <span><FontAwesomeIcon icon={faOilCan} /> {meal.macros.fat}g</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No meal plan available for this date</p>
                  )}
                </div>
              )}
            </div>
          ))}
          <button className="close-button" onClick={() => setShowPreviousMealPlans(false)}>Close</button>
        </div>
      )}
      <Analytics />
    </div>
  );
}

export default MealPlanner;