import { db } from '../firebaseConfig';
import { 
  doc, 
  setDoc, 
  getDoc, 
  query, 
  collection, 
  where, 
  orderBy, 
  limit, 
  getDocs,
  serverTimestamp 
} from 'firebase/firestore';

export async function saveMealPlan(userId, mealPlan) {
  const today = new Date().toISOString().split('T')[0];
  const mealPlanRef = doc(db, 'mealPlans', `${userId}_${today}`);
  try {
    await setDoc(mealPlanRef, { 
      userId, 
      mealPlan, 
      date: new Date(),
      createdAt: serverTimestamp() 
    });
    //console.log("Meal plan saved successfully");
  } catch (error) {
    console.error("Error saving meal plan:", error);
    throw error;
  }
}

export async function getTodaysMealPlan(userId) {
  if (!userId) {
    console.error('No user ID provided to getTodaysMealPlan');
    return null;
  }
  const today = new Date().toISOString().split('T')[0];
  const mealPlanRef = doc(db, 'mealPlans', `${userId}_${today}`);
  try {
    const mealPlanDoc = await getDoc(mealPlanRef);
    if (mealPlanDoc.exists()) {
      return mealPlanDoc.data().mealPlan;
    }
    return null;
  } catch (error) {
    console.error('Error fetching meal plan:', error);
    return null;
  }
}

export async function mealPlanExistsForToday(userId) {
  if (!userId) {
    console.error('No user ID provided to mealPlanExistsForToday');
    return false;
  }
  const today = new Date().toISOString().split('T')[0];
  const mealPlanRef = doc(db, 'mealPlans', `${userId}_${today}`);
  try {
    const mealPlanDoc = await getDoc(mealPlanRef);
    return mealPlanDoc.exists();
  } catch (error) {
    console.error('Error checking meal plan existence:', error);
    return false;
  }
}

export async function saveUserPreferences(userId, preferences) {
  const userPreferencesRef = doc(db, 'userPreferences', userId);
  await setDoc(userPreferencesRef, preferences);
}

export async function getUserPreferences(userId) {
  const userPreferencesRef = doc(db, 'userPreferences', userId);
  const userPreferencesDoc = await getDoc(userPreferencesRef);
  
  if (userPreferencesDoc.exists()) {
    const preferences = userPreferencesDoc.data();
    //console.log("Retrieved user preferences:", preferences);
    return preferences;
  }
  console.log("No user preferences found");
  return null;
}

export async function getPreviousMealPlans(userId, days) {
  const endDate = new Date();
  endDate.setHours(0, 0, 0, 0);
  const startDate = new Date(endDate);
  startDate.setDate(startDate.getDate() - days);

  const mealPlansRef = collection(db, 'mealPlans');
  const q = query(
    mealPlansRef,
    where('userId', '==', userId),
    where('date', '>=', startDate),
    where('date', '<', endDate),
    orderBy('date', 'desc'),
    limit(days)
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    date: doc.data().date,
    mealPlan: doc.data().mealPlan
  }));
}