import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { auth, db } from './firebaseConfig';
import { signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, doc, setDoc, Timestamp, query, where, orderBy, getDocs, getDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faWeight, faChartLine, faUser, faBullseye, faUtensils, faDumbbell } from '@fortawesome/free-solid-svg-icons';
import WeightGraph from './WeightGraph';
import WeightLog from './WeightLog';
import GoalWeight from './GoalWeight';
import MealPlanner from './MealPlanner';
import { Analytics } from "@vercel/analytics/react"
import ReactGA from 'react-ga4';
import { track } from '@vercel/analytics';
import './MealPlannerNew.css';

// Initialize Google Analytics
ReactGA.initialize('G-H2ML3198B9'); // Replace with your Google Analytics measurement ID

function App() {
  const [weight, setWeight] = useState('');
  const [user, setUser] = useState(null);
  const [weights, setWeights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showWeightInput, setShowWeightInput] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [activePage, setActivePage] = useState(() => {
    return localStorage.getItem('activePage') || 'log';
  });
  const [isValidWeight, setIsValidWeight] = useState(false);
  const [goalWeight, setGoalWeight] = useState('');
  const [caloriesAndMacros, setCaloriesAndMacros] = useState(null);
  const [mealPlannerData, setMealPlannerData] = useState(null);
  const [currentTab, setCurrentTab] = useState('calculator');

  const containerRef = useRef(null);

  const saveUserToDatabase = async (user) => {
    if (!user) return;

    const userRef = doc(db, 'users', user.uid);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      // User doesn't exist in the database, so let's add them
      try {
        await setDoc(userRef, {
          email: user.email,
          displayName: user.displayName,
          createdAt: new Date()
        });
        console.log("User added to database");
      } catch (error) {
        console.error("Error adding user to database:", error);
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        saveUserToDatabase(currentUser);
        fetchWeights();
        fetchGoalWeight(); // Fetch goal weight data when user logs in
        // Scroll to top after login
        if (containerRef.current) {
          containerRef.current.scrollTo(0, 0);
        }
      } else {
        setUser(null);
        setActivePage('log');
        localStorage.setItem('activePage', 'log');
        setCaloriesAndMacros(null); // Reset calorie and macro data when user logs out
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      fetchWeights();
      fetchGoalWeight();
    }
  }, [user]);

  useEffect(() => {
    localStorage.setItem('activePage', activePage);
    // Scroll to top when changing to the log page
    if (activePage === 'log' && containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [activePage]);

  useEffect(() => {
    // Add structured data
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify({
      "@context": "http://schema.org",
      "@type": "SoftwareApplication",
      "name": "Weight Tracker App",
      "applicationCategory": "HealthApplication",
      "operatingSystem": "Web",
      "description": "Track your weight, set goals, and monitor your progress with our easy-to-use weight tracker app.",
      "offers": {
        "@type": "Offer",
        "price": "0",
        "priceCurrency": "USD"
      }
    });
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    // Track page views
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const canAddWeight = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return !weights.some(entry => entry.date.toDate().getTime() === today.getTime());
  };

  const handleSave = () => {
    if (!user) {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {
          saveWeight(result.user);
        })
        .catch((error) => {
          console.error('Error during sign-in:', error);
        });
    } else {
      saveWeight(user);
    }
    setShowWeightInput(false);
  };

  const saveWeight = async (currentUser) => {
    if (!weight) {
      alert('Please enter your weight.');
      return;
    }

    const now = new Date();
    const dateString = now.toISOString().split('T')[0];

    try {
      const weightRef = doc(db, 'weights', `${currentUser.uid}_${dateString}`);

      await setDoc(weightRef, {
        uid: currentUser.uid,
        weight: parseFloat(weight),
        date: Timestamp.fromDate(now),
      });

      setWeight('');
      fetchWeights();
    } catch (error) {
      console.error('Error saving weight:', error);
      alert('Failed to save weight. Please try again.');
    }
  };

  const fetchWeights = async () => {
    if (!user) return;

    try {
      const q = query(
        collection(db, 'weights'),
        where('uid', '==', user.uid),
        orderBy('date', 'desc')
      );
      const querySnapshot = await getDocs(q);
      const weightsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWeights(weightsArray);
    } catch (error) {
      console.error('Error fetching weights:', error);
    }
  };

  const fetchGoalWeight = async () => {
    if (!user) return;
    try {
      const goalWeightDoc = await getDoc(doc(db, 'goalWeights', user.uid));
      if (goalWeightDoc.exists()) {
        const data = goalWeightDoc.data();
        if (data.userId === user.uid) {
          setGoalWeight(data.goalWeight);
          setCaloriesAndMacros({
            maintenanceCalories: data.maintenanceCalories || null,
            goalCalories: data.goalCalories || data.dailyCalories,
            macros: data.macros,
            idealWeight: data.idealWeight,
            estimatedWeeksToGoal: data.estimatedWeeksToGoal,
            weightChangeDirection: data.weightChangeDirection
          });
        } else {
          console.error('Goal weight document does not belong to the current user');
        }
      } else {
        setGoalWeight(null);
        setCaloriesAndMacros(null);
      }
    } catch (error) {
      console.error('Error fetching goal weight:', error);
    }
  };

  const handleSetGoalWeight = async (newGoalData) => {
    if (!user) return;
    try {
      const goalWeightData = {
        userId: user.uid,
        goalWeight: newGoalData.goalWeight,
        idealWeight: newGoalData.idealWeight,
        maintenanceCalories: newGoalData.maintenanceCalories,
        goalCalories: newGoalData.goalCalories,
        dailyCalories: newGoalData.goalCalories, // Keep this for backward compatibility
        macros: newGoalData.macros,
        estimatedWeeksToGoal: newGoalData.estimatedWeeksToGoal,
        weightChangeDirection: newGoalData.weightChangeDirection,
        calculatorResponses: newGoalData.calculatorResponses,
        updatedAt: new Date()
      };
      await setDoc(doc(db, 'goalWeights', user.uid), goalWeightData);
      setGoalWeight(newGoalData.goalWeight);
      setCaloriesAndMacros({
        maintenanceCalories: newGoalData.maintenanceCalories,
        goalCalories: newGoalData.goalCalories,
        dailyCalories: newGoalData.goalCalories, // Keep this for backward compatibility
        macros: newGoalData.macros,
        idealWeight: newGoalData.idealWeight,
        estimatedWeeksToGoal: newGoalData.estimatedWeeksToGoal,
        weightChangeDirection: newGoalData.weightChangeDirection
      });
    } catch (error) {
      console.error('Error saving goal weight:', error);
      alert('Failed to save goal weight. Please try again.');
    }
  };

  const totalPages = Math.ceil(weights.length / rowsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleAddWeight = async (weightToAdd) => {
    if (!user) {
      console.error('No user authenticated');
      return null;
    }

    const now = new Date();
    const dateString = now.toISOString().split('T')[0];

    try {
      const weightRef = doc(db, 'weights', `${user.uid}_${dateString}`);

      const newEntry = {
        uid: user.uid,
        weight: weightToAdd,
        date: Timestamp.fromDate(now),
      };

      console.log('Attempting to save weight:', newEntry);
      await setDoc(weightRef, newEntry);
      console.log('Weight saved successfully');

      const entryWithId = { ...newEntry, id: weightRef.id };
      setWeights(prevWeights => [entryWithId, ...prevWeights]);

      return entryWithId;
    } catch (error) {
      console.error('Error saving weight:', error);
      console.error('Error code:', error.code);
      console.error('Error message:', error.message);
      alert(`Failed to save weight. Error: ${error.message}`);
      return null;
    }
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        setWeights([]);
        setActivePage('log');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  const handleWeightChange = (e) => {
    const value = e.target.value;
    setWeight(value);
    const weightValue = parseFloat(value);
    setIsValidWeight(value.trim() !== '' && !isNaN(weightValue) && weightValue >= 1 && weightValue <= 700);
  };

  const getCurrentWeight = () => {
    if (weights.length > 0) {
      return weights[0].weight; // Assuming weights are sorted in descending order by date
    }
    return null;
  };

  const handleCalorieCalculation = (calculatedData) => {
    setCaloriesAndMacros(calculatedData);
    setCurrentTab('planner');
  };

  const handleTabChange = (tab) => {
    setActivePage(tab);
    localStorage.setItem('activePage', tab);
    
    // Track tab changes
    ReactGA.event({
      category: 'Navigation',
      action: 'Tab Change',
      label: tab
    });
    track('Tab_Changed', { tab });

    // If the user is switching to the meal planner and calorie data is not available, fetch it
    if (tab === 'mealplanner' && (!caloriesAndMacros || !caloriesAndMacros.macros)) {
      fetchGoalWeight();
    }
  };

  if (loading) {
    return (
      <div className="container">
        <p>Loading...</p>
      </div>
    );
  }

  const renderContent = () => {
    switch (activePage) {
      case 'log':
        return (
          <div className="content-area">
            <WeightLog
              weightEntries={weights}
              onWeightUpdate={handleWeightUpdate}
              onAddWeight={handleAddWeight}
              goalWeight={goalWeight}
            />
          </div>
        );
      case 'graph':
        return <WeightGraph weights={weights} />;
      case 'goal':
        return (
          <>
            <GoalWeight 
              onSetGoalWeight={handleSetGoalWeight} 
              currentGoalWeight={goalWeight ? {
                goalWeight: goalWeight,
                maintenanceCalories: caloriesAndMacros?.maintenanceCalories || 
                  <span className="recalculate-message">Recalculate to show your maintenance calories</span>,
                goalCalories: caloriesAndMacros?.goalCalories || caloriesAndMacros?.dailyCalories,
                macros: caloriesAndMacros?.macros,
                idealWeight: caloriesAndMacros?.idealWeight,
                estimatedWeeksToGoal: caloriesAndMacros?.estimatedWeeksToGoal,
                weightChangeDirection: caloriesAndMacros?.weightChangeDirection
              } : null}
              currentUser={user}
              currentWeight={getCurrentWeight()}
            />
          </>
        );
      case 'mealplanner':
        return (
          <MealPlanner
            caloriesAndMacros={caloriesAndMacros}
            mealPlannerData={mealPlannerData}
            setMealPlannerData={setMealPlannerData}
            handleTabChange={handleTabChange}
            user={user}
          />
        );
      case 'workoutplanner':
        return (
          <div className="workout-planner-coming-soon">
            <h2>Workout Planner</h2>
            <p>Coming Soon!</p>
          </div>
        );
      case 'profile':
        return <Profile user={user} handleSignOut={handleSignOut} />;
      default:
        return null;
    }
  };
  
  const handleWeightUpdate = async (id, updatedWeight, updatedDate) => {
    try {
      const weightRef = doc(db, 'weights', id);
      await setDoc(weightRef, { 
        weight: updatedWeight,
        date: Timestamp.fromDate(updatedDate)
      }, { merge: true });
      fetchWeights();
    } catch (error) {
      console.error('Error updating weight:', error);
      alert(`Failed to update weight. Error: ${error.message}`);
    }
  };

  return (
    <div className="container" ref={containerRef}>
      {!user ? (
        <div className="login-container">
          <h1>Weight Tracker</h1>
          <input
            type="number"
            id="weight-input"
            placeholder="Enter Today's Weight in Kilograms"
            value={weight}
            onChange={handleWeightChange}
            step="0.1"
            min="0"
          />
          <button id="save-button" onClick={handleSave} disabled={!isValidWeight}>
            Save
          </button>
        </div>
      ) : (
        <>
          <h1>Your Weight Tracker</h1>
          {renderContent()}
          <div className="bottom-menu">
            <a
              className={`bottom-menu-item ${activePage === 'log' ? 'active' : ''}`}
              onClick={() => handleTabChange('log')}
            >
              <FontAwesomeIcon icon={faWeight} className="bottom-menu-icon" />
              <span>Log</span>
            </a>
            <a
              className={`bottom-menu-item ${activePage === 'graph' ? 'active' : ''}`}
              onClick={() => handleTabChange('graph')}
            >
              <FontAwesomeIcon icon={faChartLine} className="bottom-menu-icon" />
              <span>Graph</span>
            </a>
            <a
              className={`bottom-menu-item ${activePage === 'goal' ? 'active' : ''}`}
              onClick={() => handleTabChange('goal')}
            >
              <FontAwesomeIcon icon={faBullseye} className="bottom-menu-icon" />
              <span>Goal</span>
            </a>
            <a
              className={`bottom-menu-item ${activePage === 'mealplanner' ? 'active' : ''}`}
              onClick={() => handleTabChange('mealplanner')}
            >
              <FontAwesomeIcon icon={faUtensils} className="bottom-menu-icon" />
              <span>Diet</span>
            </a>
            <a
              className={`bottom-menu-item ${activePage === 'workoutplanner' ? 'active' : ''}`}
              onClick={() => handleTabChange('workoutplanner')}
            >
              <FontAwesomeIcon icon={faDumbbell} className="bottom-menu-icon" />
              <span>Workout</span>
            </a>
            <a
              className={`bottom-menu-item ${activePage === 'profile' ? 'active' : ''}`}
              onClick={() => handleTabChange('profile')}
            >
              <FontAwesomeIcon icon={faUser} className="bottom-menu-icon" />
              <span>Profile</span>
            </a>
          </div>
        </>
      )}
      <Analytics />
    </div>
  );
}

function Profile({ user, handleSignOut }) {
  return (
    <div className="profile">
      <h2>Profile</h2>
      <p>Name: {user.displayName}</p>
      <p>Email: {user.email}</p>
      <button className="sign-out-button" onClick={handleSignOut}>
        Sign Out
      </button>
    </div>
  );
}

export default App;