import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faSave, faTimes, faBullseye, faWeight, faFire, faCarrot, faEgg, faOilCan, faClock, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { calculateWeightPlan } from './utils/weightCalculator';
import { track } from '@vercel/analytics';
import ReactGA from 'react-ga4';

function GoalWeight({ onSetGoalWeight, currentGoalWeight, currentUser, currentWeight }) {
  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [height, setHeight] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [bodyType, setBodyType] = useState('');
  const [lifestyle, setLifestyle] = useState('');
  const [calculationResult, setCalculationResult] = useState(null);
  const [savedGoalData, setSavedGoalData] = useState(null);

  useEffect(() => {
    if (currentGoalWeight) {
      setSavedGoalData(currentGoalWeight);
    }
  }, [currentGoalWeight]);

  const handleCalculate = (e) => {
    e.preventDefault();
    const result = calculateWeightPlan(
      currentWeight,
      null,
      parseFloat(height),
      parseInt(age),
      gender,
      bodyType,
      lifestyle
    );
    setCalculationResult(result);
    setShowModal(true);
    track('Goal_Weight_Calculated');
    ReactGA.event({
      category: 'GoalWeight',
      action: 'Calculate',
      label: 'Goal Weight Calculated'
    });
  };

  const handleSave = () => {
    if (calculationResult) {
      const goalData = {
        goalWeight: calculationResult.goalWeight,
        idealWeight: calculationResult.idealBodyWeight,
        maintenanceCalories: calculationResult.maintenanceCalories, // Add this line
        goalCalories: calculationResult.goalCalories,
        macros: calculationResult.macros,
        estimatedWeeksToGoal: calculationResult.estimatedWeeksToGoal,
        weightChangeDirection: calculationResult.weightChangeDirection,
        calculatorResponses: {
          height,
          age,
          gender,
          bodyType,
          lifestyle
        }
      };
      onSetGoalWeight(goalData);
      setSavedGoalData(goalData);
      setShowModal(false);
      setShowForm(false);
      track('Goal_Weight_Saved');
      ReactGA.event({
        category: 'GoalWeight',
        action: 'Save',
        label: 'Goal Weight Saved'
      });
    }
  };

  const handleRecalculate = () => {
    setShowForm(true);
  };

  const handleCancelRecalculate = () => {
    setShowForm(false);
  };

  return (
    <div className="goal-weight-container">
      <h2>Ideal Weight Calculator</h2>
      <p className="ibw-info">The Ideal Body Weight (IBW) formula used in this calculator is a variation of the Devine formula.</p>
      <div className="weight-card current-weight-card">
        <div className="weight-card-content">
          <div className="weight-date-time">
            <div className="weight-date">Current Weight</div>
          </div>
          <div className="weight-value-container">
            <span className="weight-value">
              {currentWeight}
              <span className="weight-unit">kg</span>
            </span>
          </div>
        </div>
      </div>

      {!showForm && !savedGoalData && (
        <button className="calculate-button theme-button" onClick={() => setShowForm(true)}>
          <FontAwesomeIcon icon={faCalculator} /> Calculate Ideal Weight
        </button>
      )}

      {showForm && (
        <form onSubmit={handleCalculate}>
          <div className="form-group">
            <label htmlFor="height">Height (cm):</label>
            <input
              type="number"
              id="height"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
              required
              min="100"
              max="250"
            />
          </div>
          <div className="form-group">
            <label htmlFor="age">Age:</label>
            <input
              type="number"
              id="age"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              required
              min="1"
              max="120"
            />
          </div>
          <div className="form-group">
            <label htmlFor="gender">Gender:</label>
            <select
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="">Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="bodyType">Which of these best describes your body type?</label>
            <select
              id="bodyType"
              value={bodyType}
              onChange={(e) => setBodyType(e.target.value)}
              required
            >
              <option value="">Select body type</option>
              <option value="non-muscular">I have a normal build without much visible muscle.</option>
              <option value="muscular">I have noticeable muscle definition and strength.</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="lifestyle">Which of these best describes your daily activity level?</label>
            <select
              id="lifestyle"
              value={lifestyle}
              onChange={(e) => setLifestyle(e.target.value)}
              required
            >
              <option value="">Select lifestyle</option>
              <option value="sedentary">I spend most of my day sitting (e.g., office job, research studies) and don't exercise regularly.</option>
              <option value="moderatelyActive">I move around quite a bit during the day (e.g., teacher, shopkeeper, physical sales) or exercise 3-4 times a week.</option>
              <option value="veryActive">I have a physically demanding job (e.g., gym trainer, athlete) or exercise intensely 6-7 times a week.</option>
            </select>
          </div>
          <div className="form-buttons">
            <button type="submit" className="calculate-button theme-button">Calculate</button>
            <button type="button" className="cancel-button theme-button" onClick={handleCancelRecalculate}>
              <FontAwesomeIcon icon={faTimes} /> Cancel
            </button>
          </div>
        </form>
      )}

      {savedGoalData && !showForm && (
        <div className="saved-goal-data">
          <h3>Your Goal Weight and Macros</h3>
          <div className="goal-info-cards">
            <div className="info-card-row">
              <div className="info-card">
                <FontAwesomeIcon icon={faWeight} className="info-icon" />
                <div className="info-content">
                  <h4>Ideal Weight</h4>
                  <p>{savedGoalData.idealWeight} kg</p>
                </div>
              </div>
              <div className="info-card">
                <FontAwesomeIcon icon={faBullseye} className="info-icon" />
                <div className="info-content">
                  <h4>Goal Weight</h4>
                  <p>{savedGoalData.goalWeight} kg</p>
                </div>
              </div>
            </div>
            <div className="info-card-row">
              <div className="info-card">
                <FontAwesomeIcon icon={faFire} className="info-icon" />
                <div className="info-content">
                  <h4>Maintenance Calories</h4>
                  <p>{savedGoalData.maintenanceCalories} kcal</p>
                </div>
              </div>
              <div className="info-card">
                <FontAwesomeIcon icon={faFire} className="info-icon" />
                <div className="info-content">
                  <h4>Goal Calories</h4>
                  <p>{savedGoalData.goalCalories} kcal</p>
                </div>
              </div>
            </div>
            <div className="info-card-row macros-row">
              {savedGoalData.macros && (
                <>
                  <div className="info-card macro-card">
                    <FontAwesomeIcon icon={faEgg} className="info-icon" />
                    <div className="info-content">
                      <h4>Protein</h4>
                      <p>{savedGoalData.macros.protein.grams}g ({savedGoalData.macros.protein.percentage}%)</p>
                    </div>
                  </div>
                  <div className="info-card macro-card">
                    <FontAwesomeIcon icon={faCarrot} className="info-icon" />
                    <div className="info-content">
                      <h4>Carbs</h4>
                      <p>{savedGoalData.macros.carbs.grams}g ({savedGoalData.macros.carbs.percentage}%)</p>
                    </div>
                  </div>
                  <div className="info-card macro-card">
                    <FontAwesomeIcon icon={faOilCan} className="info-icon" />
                    <div className="info-content">
                      <h4>Fat</h4>
                      <p>{savedGoalData.macros.fat.grams}g ({savedGoalData.macros.fat.percentage}%)</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <button onClick={handleRecalculate} className="recalculate-button theme-button">
            <FontAwesomeIcon icon={faCalculator} /> Recalculate Ideal Weight & Calories
          </button>
        </div>
      )}

      {showModal && calculationResult && (
        <div className="modal">
          <div className="modal-content calculation-result">
            <button className="close-button" onClick={() => setShowModal(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h3>Calculation Result</h3>
            <div className="goal-info-cards">
              <div className="info-card-row">
                <div className="info-card">
                  <FontAwesomeIcon icon={faWeight} className="info-icon" />
                  <div className="info-content">
                    <h4>Ideal Weight</h4>
                    <p>{calculationResult.idealBodyWeight} kg</p>
                  </div>
                </div>
                <div className="info-card">
                  <FontAwesomeIcon icon={faBullseye} className="info-icon" />
                  <div className="info-content">
                    <h4>Goal Weight</h4>
                    <p>{calculationResult.goalWeight} kg</p>
                  </div>
                </div>
              </div>
              <div className="info-card-row">
                <div className="info-card">
                  <FontAwesomeIcon icon={faFire} className="info-icon" />
                  <div className="info-content">
                    <h4>Maintenance Calories</h4>
                    <p>{calculationResult.maintenanceCalories} kcal</p>
                  </div>
                </div>
                <div className="info-card">
                  <FontAwesomeIcon icon={faFire} className="info-icon" />
                  <div className="info-content">
                    <h4>Goal Calories</h4>
                    <p>{calculationResult.goalCalories} kcal</p>
                  </div>
                </div>
              </div>
              <div className="info-card-row macros-row">
                <div className="info-card macro-card">
                  <FontAwesomeIcon icon={faEgg} className="info-icon" />
                  <div className="info-content">
                    <h4>Protein</h4>
                    <p>{calculationResult.macros.protein.grams}g ({calculationResult.macros.protein.percentage}%)</p>
                  </div>
                </div>
                <div className="info-card macro-card">
                  <FontAwesomeIcon icon={faCarrot} className="info-icon" />
                  <div className="info-content">
                    <h4>Carbs</h4>
                    <p>{calculationResult.macros.carbs.grams}g ({calculationResult.macros.carbs.percentage}%)</p>
                  </div>
                </div>
                <div className="info-card macro-card">
                  <FontAwesomeIcon icon={faOilCan} className="info-icon" />
                  <div className="info-content">
                    <h4>Fat</h4>
                    <p>{calculationResult.macros.fat.grams}g ({calculationResult.macros.fat.percentage}%)</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="goal-progress-info">
              <div className="info-card">
                <FontAwesomeIcon icon={faClock} className="info-icon" />
                <div className="info-content">
                  <h4>Estimated Time to Goal</h4>
                  <p>
                    {calculationResult.estimatedWeeksToGoal} weeks 
                    <span className="weight-direction">
                      ({calculationResult.weightChangeDirection === 'loss' ? 'Weight Loss' : 'Weight Gain'})
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <button onClick={handleSave} className="save-button theme-button">
              <FontAwesomeIcon icon={faSave} /> Save Goal Weight and Macros
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default GoalWeight;